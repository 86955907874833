import styled from 'styled-components'
import { Media, typography } from '@renderbus/common/theme'
import { H3, H4 } from '@renderbus/common/components'
import Bg from '../images/xgtnzhd/bg.png'
import BgMb from '../images/xgtnzhd/bg-mb.png'
import BgTextImg from '../images/xgtnzhd/bg-text.png'
import BgTextImgMb from '../images/xgtnzhd/bg-text-mb.png'
import TagImg from '../images/xgtnzhd/tag.png'
import ActivityDetailFooterImg from '../images/xgtnzhd/tag-container.png'
import ActivityDetailFooterImgMb from '../images/xgtnzhd/tag-container-mb.png'
import BlockFooterLeftImg from '../images/xgtnzhd/left-footer.png'
import BlockFooterRightImg from '../images/xgtnzhd/right-footer.png'
import LeftGiftImg from '../images/xgtnzhd/left-gift.png'
import LeftGiftImgMb from '../images/xgtnzhd/left-gift-mb.png'
import RightGiftImg from '../images/xgtnzhd/right-gift.png'
import RightGiftImgMb from '../images/xgtnzhd/right-gift-mb.png'
import ArrowImg from '../images/xgtnzhd/right-arrow.png'
import ArrowImgMb from '../images/xgtnzhd/right-arrow-mb.png'

export const XgtnzhdContent = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  aling-items: center;
  background-color: #060606;
`

export const RechargeButton = styled.a`
  width: 152px;
  height: 38px;
  background: #53d769;
  border-radius: 19px;
  line-height: 38px;
  font-size: ${typography.text};
  text-align: center;
  margin-top: -10px;
  ${Media.lessThan(Media.small)} {
    width: 22.4vw;
    height: 6.4vw;
    line-height: 6.4vw;
    font-size: 3.2vw;
    margin-top: 3.5%;
  }
`

export const PosterTextImg = styled.div`
  background: ${`url(${BgTextImg})`} no-repeat center center;
  background-size: 100%;
  height: 370px;
  width: 874px;
  margin-top: 101px;
  margin-left: 58px;
  ${Media.lessThan(Media.small)} {
    background: ${`url(${BgTextImgMb})`} no-repeat center center;
    background-size: 100%;
    width: 100%;
    max-height: 45.33vw;
    margin-top: 19.47vw;
    margin-left: 0;
  }
`

export const Date = styled(H4)`
  font-weight: 400;
  margin-top: 30px;
  color: #bec3c5;
  ${Media.lessThan(Media.small)} {
    margin-top: 4.54%;
    font-size: 3.2vw;
  }
`

export const XgtnzhdPoster = styled.div`
  background: ${`url(${Bg})`} no-repeat center center;
  background-size: 100%;
  width: 100%;
  height: 700px;
  margin-top: 57px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Media.lessThan(Media.small)} {
    background: ${`url(${BgMb})`} no-repeat center center;
    background-size: 100%;
    width: 100%;
    max-height: 114.53vw;
  }
`

export const SectionImg = styled.img`
  width: 400px;
  height: 45px;
  ${Media.lessThan(Media.small)} {
    width: 53.33vw;
    height: auto;
  }
`

export const Block = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #1d1d1d;
  width: 1400px;
  border-radius: 12px;
  justify-content: center;
  position: relative;
  height: ${p => p.height || '400px'};
  margin-top: ${p => p.marginTop || '100px'};
  ${Media.lessThan(Media.small)} {
    width: 88.8vw;
    height: 100vw;
    margin-top: 9vw;
  }
`
export const BlockFooter = styled.div`
  background: ${p =>
    p.left
      ? `url(${BlockFooterLeftImg}) no-repeat center center`
      : `url(${BlockFooterRightImg}) no-repeat center center`};
  background-size: 100%;
  width: 144px;
  height: 26px;
  position: absolute;
  left: ${p => p.left && '0px'};
  right: ${p => p.right && '0px'};
  bottom: 0;
  ${Media.lessThan(Media.small)} {
    width: 17.6vw;
    bottom: -1.8vw;
  }
`

export const DetailBlock = styled(Block)`
  height: 400px;
  margin-top: 100px;
`

export const StepBlock = styled(Block)`
  height: 480px;
  margin-top: 66px;
  ${Media.lessThan(Media.small)} {
    height: 65.6vw;
    ${BlockFooter} {
      display: none;
    }
  }
`

export const RuleBlock = styled(Block)`
  height: 452px;
  margin-top: 64px;
  ${Media.lessThan(Media.small)} {
    min-height: 91.2vw;
    height: auto;
  }
`

export const Section = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${p => p.marginTop || '88px'};
  margin-bottom: ${p => p.marginBottom || '106px'};
  ${Media.lessThan(Media.small)} {
    margin-top: ${p => p.marginTopMb || '6.8vw'};
    margin-bottom: ${p => p.marginBottomMb || '10.53vw'};
  }
`

export const Tag = styled.div`
  background: ${`url(${TagImg})`} no-repeat center center;
  background-size: 100%;
  width: 640px;
  height: 160px;
  margin: -37px 14.5px 0;
  position: relative;
  display: flex;
  ${Media.lessThan(Media.small)} {
    width: 84vw;
    height: 21.33vw;
    margin: -4.8% 2.4vw 0;
  }
`

export const Amount = styled.img`
  height: 80px;
  margin-top: 38px;
  margin-left: ${p => p.marginLeft || '59px'};
  ${Media.lessThan(Media.small)} {
    height: 10.66vw;
    margin: 4.8vw 0 5.73vw ${p => p.marginLeftMb || '8.53vw'};
  }
`

export const NowDiscount = styled(H3)`
  color: #50ff6e;
  font-weight: 400;
  max-width: 177px;
  ${Media.lessThan(Media.small)} {
    font-size: 3.2vw;
    max-width: 25.33vw;
  }
`

export const OldDiscount = styled(H4)`
  color: #c8c8c8;
  text-decoration: line-through;
  font-weight: 400;
  ${Media.lessThan(Media.small)} {
    font-size: 2.53vw;
    margin-top: 1vw;
  }
`

export const DiscountContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 360px;
  top: ${p => p.top || '50px'};
  ${Media.lessThan(Media.small)} {
    left: 47.46vw;
    top: ${p => p.topMb || '24%'};
  }
`

export const ActivityDetailFooter = styled.div`
  background: ${`url(${ActivityDetailFooterImg})`} no-repeat center center;
  background-size: 100%;
  width: 1459px;
  height: 189px;
  position: absolute;
  bottom: -29.5px;
  ${Media.lessThan(Media.small)} {
    background: ${`url(${ActivityDetailFooterImgMb})`} no-repeat center center;
    background-size: 100%;
    width: 108%;
    bottom: -19%;
  }
`
export const StepArrow = styled.div`
  background: ${`url(${ArrowImg})`} no-repeat center center;
  background-size: 100%;
  width: 91px;
  height: 19px;
  position: absolute;
  top: 50%;
  left: 125%;
  box-sizing: border-box;
  ${Media.lessThan(Media.small)} {
    background: ${`url(${ArrowImgMb})`} no-repeat center center;
    background-size: 100%;
    width: 2.93vw;
    height: 2.53vw;
    left: 110%;
  }
`

export const Step = styled.div`
  position: relative;
  width: 240px;
  height: 240px;
  background: #282828;
  border-radius: 12px;
  &:hover {
    border: 2px solid #50ff6e;
    ${StepArrow} {
      left: calc(125% + 3px);
      ${Media.lessThan(Media.small)} {
        left: calc(110% + 2px);
      }
    }
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: ${p => p.paddingTop || '38px'};
  img {
    max-height: 69px;
  }
  ${Media.lessThan(Media.small)} {
    width: 27.02%;
    height: 32vw;
    padding-top: ${p => p.paddingTopMb || '4.53vw'};
    img {
      max-height: ${p => p.maxWidth || '8.66vw'};
    }
  }
`

export const StepContainer = styled.div`
  width: 100%;
  padding: 80px 140px 0;
  display: flex;
  justify-content: space-between;
  ${Media.lessThan(Media.small)} {
    padding: 16.26% 2.7% 0;
  }
`

export const LabelContainer = styled.div`
  margin-top: 40px;
  p {
    margin: 0;
    padding: 0;
    text-align: center;
    color: white;
    font-size: ${typography.h4};
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 5.2vw;
    p {
      font-size: 2.66vw;
    }
  }
`

export const Calculation = styled.div`
  color: #50ff6e;
  font-size: ${typography.h4};
  img {
    max-width: 45px;
    &:first-child {
      max-width: 30px;
    }
  }
  ${Media.lessThan(Media.small)} {
    font-size: 3.19vw;
    img {
      max-width: 6vw;
      position: relative;
      left: -3px;
      &:first-child {
        max-width: 4vw;
      }
    }
  }
`

export const RulesContainer = styled.ol`
  padding: 81px 111px 0 101px;
  font-size: 20px;
  color: #bec3c5;
  ${Media.lessThan(Media.small)} {
    padding: 10.8vw 4.8vw 10.66vw 5.33vw;
    font-size: 2.66vw;
  }
`

export const Rule = styled.li`
  color: ${p => p.activated && '#50FF6E'};
  margin: 0;
  ${Media.lessThan(Media.small)} {
    margin-top: 1%;
  }
`

export const Tada = styled.div`
  background: ${p =>
    p.left
      ? `url(${LeftGiftImg}) no-repeat center center`
      : `url(${RightGiftImg}) no-repeat center center`};
  background-size: 100%;
  width: 244px;
  height: 231px;
  position: absolute;
  left: ${p => p.left && '-201px'};
  right: ${p => !p.left && '-201px'};
  top: -105px;
  z-index: 1;
  ${Media.lessThan(Media.small)} {
    background: ${p =>
      p.left
        ? `url(${LeftGiftImgMb}) no-repeat center center`
        : `url(${RightGiftImgMb}) no-repeat center center`};
    background-size: 100%;
    width: 10.4vw;
    height: 15.46vw;
    left: ${p => p.left && '-5.2vw'};
    right: ${p => !p.left && '-5.2vw'};
    top: 0px;
  }
`
